import React, {useCallback, useMemo, useState} from "react";

import styles from "./styles.module.scss";
import {Loader, UpgradeNowTooltip} from "components";
import {LockIcon, PlusIcon} from "assets/icons";
import {
	isStarterTypeUserSelector,
	userOrganisationTypeSelector,
} from "store/mapx/user/userSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import SpreadSheetIcon from "mapx-pages/Project/Share/Icons/spreadsheetIcon";
import ProjectCollaboration from "mapx-pages/Project/Share/ProjectCollaboration";
import {GeneralButton} from "mapx-components";
import PowerpointIcon from "mapx-pages/Project/Share/Icons/powerpointIcon";
import classNames from "classnames";
import ProjectReportTable from "mapx-pages/Project/Share/ProjectReportTable";
import {
	addNewRowOnProjectReportConfigTable,
	downloadProjectReportAsPowerpoint,
	downloadProjectReportAsSpreadsheet,
} from "store/mapx/project/projectReportDownloadAsyncActions";
import {SICandidate} from "api/candidateApi/types";
import {targetListCandidatesSelector} from "store/mapx/target-list/targetListSelectors";

const ShareProject = () => {
	const dispatch = useAppDispatch();

	const savedPeoples: SICandidate[] = useAppSelector(targetListCandidatesSelector);

	const organisationType = useAppSelector(userOrganisationTypeSelector);

	const isStarterTypeUser = useAppSelector(isStarterTypeUserSelector);

	const [downloadProcessing, setDownloadProcessing] = useState(false);

	const [downloadPowerpointProcessing, setDownloadPowerpointProcessing] = useState(false);

	const LOCK_ICON_COMPONENT = <LockIcon className={styles.downloadIcon} />;

	const SPREADSHEET_ICON_COMPONENT = <SpreadSheetIcon />;

	const [activeIcon, setActiveIcon] = useState(SPREADSHEET_ICON_COMPONENT);

	const LoaderComponent = <Loader height={16} width={16} type="TailSpin" color="#fff" />;

	const handleDownloadExcel = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadProcessing(true);

		await dispatch(downloadProjectReportAsSpreadsheet());

		setDownloadProcessing(false);
	}, [isStarterTypeUser, dispatch]);

	const handleDownloadPowerpoint = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadPowerpointProcessing(true);

		await dispatch(downloadProjectReportAsPowerpoint());

		setDownloadPowerpointProcessing(false);
	}, [dispatch, isStarterTypeUser]);

	const displayPowerpointDownload = useMemo(() => {
		return organisationType === "Internal";
	}, [organisationType]);

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<div className={styles.sectionTitle}>Report Download</div>

				{savedPeoples?.length > 0 && (
					<div className={styles.sectionContent}>
						<div className={styles.reportColumn}>
							<span className={styles.reportTitle}>
								Group people by status and choose a layout style for their profile
							</span>

							<ProjectReportTable />
						</div>

						<div className={styles.actionsColumn}>
							<div className={styles.buttonsWrapper}>
								<GeneralButton
									title="Add Status"
									className={styles.addStatusButton}
									loadingSave={false}
									icon={<PlusIcon />}
									onClick={() => dispatch(addNewRowOnProjectReportConfigTable())}
								/>
							</div>

							<div className={styles.buttonsWrapper}>
								<span
									className={classNames(styles.downloadButton, {
										[styles.disabled]: downloadProcessing,
									})}
									onClick={handleDownloadExcel}
									onMouseEnter={() => {
										if (isStarterTypeUser) {
											setActiveIcon(LOCK_ICON_COMPONENT);
										}
									}}
									onMouseLeave={() => {
										if (isStarterTypeUser) {
											setActiveIcon(SPREADSHEET_ICON_COMPONENT);
										}
									}}
									data-tooltip-id={"downloadProjectReport"}
								>
									{downloadProcessing ? LoaderComponent : activeIcon}{" "}
									{!downloadProcessing && "Spreadsheet Download"}
									{isStarterTypeUser && (
										<UpgradeNowTooltip id={"downloadProjectReport"} />
									)}
								</span>

								{displayPowerpointDownload && (
									<GeneralButton
										title="Powerpoint Download"
										className={classNames(styles.downloadButton, {
											[styles.disabled]: downloadPowerpointProcessing,
										})}
										loadingSave={downloadPowerpointProcessing}
										icon={<PowerpointIcon />}
										onClick={handleDownloadPowerpoint}
									/>
								)}
							</div>
						</div>
					</div>
				)}

				{!savedPeoples?.length && (
					<div className={styles.sectionContent}>
						<span className={styles.reportTitle}>
							Add people to this project to download the report.
						</span>
					</div>
				)}
			</div>

			<div className={styles.section} style={{width: "50%"}}>
				<div className={styles.sectionTitle}>Project Collaboration</div>

				<div className={styles.sectionContent}>
					<ProjectCollaboration />
				</div>
			</div>
		</div>
	);
};

export default ShareProject;
