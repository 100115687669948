import React, {useCallback, useEffect, useMemo} from "react";

import styles from "./styles.module.scss";
import ProjectCandidatesFilter from "mapx-pages/Project/ProjectCandidatesFilter";
import {useSelector} from "react-redux";
import {
	additionalProfileFiltersSelector,
	apCandidateOrderingSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {useAppDispatch, useAppSelector} from "hooks";
import {getFilteredSavedPeople} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import classNames from "classnames";
import AddPeople from "../AddPeople/index";
import {Dropdown} from "components";
import {sortOption} from "mapx-pages/Project/SavedPeople/FilterSection/type";
import {ArrowDown} from "assets/icons";
import {setAPFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import {toggleAllTargetCompaniesOnAPCandidateFilter} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";

const OPTIONS: sortOption[] = [
	{value: "full_name", name: "Full Name A to Z"},
	{value: "-full_name", name: "Full Name Z to A"},
	{value: "company_name", name: "Company A to Z"},
	{value: "-company_name", name: "Company Z to A"},
	{value: "-is_recommended", name: "Recommended"},
	{value: "-added_to_project_date", name: "Recently Added"},
];

const FilterSection = () => {
	const dispatch = useAppDispatch();

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const selectedSort = useSelector(apCandidateOrderingSelector);

	const targetListId = useAppSelector(targetListIDSelector);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...filterSelection}, true);

		if (payload !== null) {
			return {
				filters: {
					...payload.filters,
					target_list: targetListId,
				},
				sorting_options: {
					...payload.sorting_options,
				},
			};
		} else return null;
	}, [filterSelection, targetListId]);

	const fetchResults = useCallback(
		async (payload: Omit<ICandidateSearchApiRequestPayload, "pagination">): Promise<void> => {
			await dispatch(getFilteredSavedPeople(payload));
		},
		[dispatch],
	);

	useEffect(() => {
		if (candidateFilterPayload !== null) {
			fetchResults(candidateFilterPayload);
		}
	}, [candidateFilterPayload, fetchResults]);

	useEffect(() => {
		return () => {
			dispatch(toggleAllTargetCompaniesOnAPCandidateFilter(false));
		};
	}, [dispatch]);

	const sortOnSelect = useCallback(
		(option: sortOption) => {
			dispatch(setAPFilter({attribute: "ordering", value: option.value}));
		},
		[dispatch],
	);

	const displayedSelectedSort = useMemo(() => {
		return OPTIONS.find((option) => option.value === selectedSort)?.name;
	}, [selectedSort]);

	return (
		<div className={styles.filterSectionWrapper}>
			<div className={styles.left}>
				<ProjectCandidatesFilter
					canUserClick={true}
					source={"saved_people"}
					className={classNames(styles.filterButton, {
						[styles.hasFilter]: candidateFilterPayload !== null,
					})}
					titleClass={styles.filterButtonTitle}
					shouldDisplayResetFilter={candidateFilterPayload !== null}
				/>

				<Dropdown
					onSelect={sortOnSelect}
					options={OPTIONS}
					getOptionLabel={(option) => option.name}
					defaultSelectedOption={OPTIONS.find((s) => s.value === selectedSort) || null}
					placeholder={
						<div className={styles.dropdownPlaceholder}>
							<div>
								<span>Sort By</span> {displayedSelectedSort}
							</div>
							<div>
								<ArrowDown />
							</div>
						</div>
					}
				/>
			</div>

			<div className={styles.right}>
				<AddPeople />
			</div>
		</div>
	);
};

export default FilterSection;
