import FilterIcon from "assets/icons/IconFilter";
import classNames from "classnames";
import {useAppDispatch, useAppSelector, useOutsideClick} from "hooks";
import {Accordion} from "mapx-components";
import CompanyFilterForAP from "mapx-pages/Project/ProjectCandidatesFilter/CompanyFilter";
import DiversityFilterForAP from "./DiversityFilter";
import IndustryFilterForAP from "./IndustryFilter";
import KeywordFilterForAP from "./KeywordFilter";
import LocationFilterForAP from "./LocationFilter";
import SeniorityFilterForAP from "./SeniorityFilter";
import JobFunctionFilterForAP from "./JobFunctionFilter";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {
	clearAPFilters,
	setAPFilter,
	setProjectResultsTabActiveContent,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	additionalProfileFiltersSelector,
	apTLCompaniesByTargetListPositionSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import styles from "./styles.module.scss";
import {apAllSpecialismsCountSelector} from "store/mapx/additional-profiles/apJobFunctionFilterSelectors";
import {allAPKeywordsCountSelector} from "store/mapx/additional-profiles/apKeywordFilterSelectors";
import {TProjectCandidatesFilterProps} from "mapx-pages/Project/ProjectCandidatesFilter/types";
import {ResetFilters} from "components";
import {ChevronIcon} from "assets/icons";
import LockIcon from "assets/icons/LockIcon";
import APHeadcountFilter from "mapx-pages/Project/ProjectCandidatesFilter/HeadcountFilter";
import APCompanyTypeFilter from "mapx-pages/Project/ProjectCandidatesFilter/CompanyTypeFilter";
import {apCandidatesAllCompanyHeadcountCountSelector} from "store/mapx/additional-profiles/apCompanyHeadcountFilterSelectors";
import APRevenueFilter from "mapx-pages/Project/ProjectCandidatesFilter/RevenuetFilter";
import {apCandidatesAllCompanyRevenueCountSelector} from "store/mapx/additional-profiles/apCompanyRevenueFilterSelectors";

const ProjectCandidatesFilter = ({
	canUserClick,
	source,
	activeContent,
	className,
	activeClass,
	titleClass,
	shouldDisplayResetFilter,
	disabled = false,
}: TProjectCandidatesFilterProps) => {
	const additionalProfileFilters = useAppSelector(additionalProfileFiltersSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const apAllSpecialismsCount = useAppSelector(apAllSpecialismsCountSelector);

	const apAllKeywordCount = useAppSelector(allAPKeywordsCountSelector);

	const [filterOpened, setFilterOpened] = useState<boolean>(false);

	const ref = useRef<HTMLDivElement>(null);

	const headcountCount = useAppSelector(apCandidatesAllCompanyHeadcountCountSelector);

	const revenueCount = useAppSelector(apCandidatesAllCompanyRevenueCountSelector);

	const selectedTLCompanies = useAppSelector((state) =>
		apTLCompaniesByTargetListPositionSelector(state),
	);

	const dispatch = useAppDispatch();

	const lockFilterForFreemium = useMemo(
		() => userLicenseTypeName === "Starter",
		[userLicenseTypeName],
	);

	const handleAPFilterChange = useCallback(
		(paramName: string, values: string[] | number[] | string | number) => {
			dispatch(setAPFilter({attribute: paramName, value: values}));
		},
		[dispatch],
	);

	const filters = useMemo(() => {
		const diversityCount =
			(additionalProfileFilters["gender_diversity_importance"] !== null ? 1 : 0) +
			(additionalProfileFilters["ethnic_diversity_importance"] !== null ? 1 : 0);

		return [
			{
				name: "Companies",
				key: "current_companies",
				type: "additional_profiles",
				filter: <CompanyFilterForAP />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count:
					additionalProfileFilters.current_companies.length +
					additionalProfileFilters.previous_companies.length +
					additionalProfileFilters.companies.length +
					selectedTLCompanies.length,
			},

			{
				name: "Seniority",
				key: "current_seniority",
				type: "additional_profiles",
				filter: <SeniorityFilterForAP setFilterParam={handleAPFilterChange} />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: additionalProfileFilters["current_seniority"]?.length || 0,
			},

			{
				name: "Function & Specialisms",
				key: "job_functions",
				type: "additional_profiles",
				filter: <JobFunctionFilterForAP />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: apAllSpecialismsCount,
			},

			{
				name: "Locations",
				key: "countries",
				type: "additional_profiles",
				filter: <LocationFilterForAP />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count:
					additionalProfileFilters.countries.length +
					additionalProfileFilters.regions.length,
			},

			{
				name: "Keyword",
				key: "text_keywords_or",
				type: "additional_profiles",
				filter: <KeywordFilterForAP />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: apAllKeywordCount,
			},

			{
				name: "Diversity",
				key: "diversity",
				type: "additional_profiles",
				filter: <DiversityFilterForAP setFilterParam={handleAPFilterChange} />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: diversityCount,
			},

			{
				name: "Industries",
				key: "industries",
				type: "additional_profiles",
				filter: <IndustryFilterForAP />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count:
					additionalProfileFilters.current_industries.length +
					additionalProfileFilters.previous_industries.length +
					additionalProfileFilters.industries.length,
			},

			{
				name: "Company Headcount",
				key: "headcount",
				type: "additional_profiles",
				filter: <APHeadcountFilter />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: headcountCount,
			},

			{
				name: "Company Revenue",
				key: "revenue",
				type: "additional_profiles",
				filter: <APRevenueFilter />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count: revenueCount,
			},

			{
				name: "Company Type",
				key: "company_type",
				type: "additional_profiles",
				filter: <APCompanyTypeFilter />,
				disabled: lockFilterForFreemium,
				displayLock: lockFilterForFreemium,
				count:
					additionalProfileFilters.company_type.length +
					additionalProfileFilters.current_company_type.length +
					additionalProfileFilters.previous_company_type.length,
			},
		];
	}, [
		additionalProfileFilters,
		apAllKeywordCount,
		apAllSpecialismsCount,
		headcountCount,
		revenueCount,
		handleAPFilterChange,
		lockFilterForFreemium,
		selectedTLCompanies?.length,
	]);

	const handleAPFilterClick = async () => {
		if (source === "results" && activeContent !== "search_results") {
			dispatch(setProjectResultsTabActiveContent("search_results"));
		}

		setFilterOpened(!filterOpened);
	};

	useOutsideClick(ref, () => setFilterOpened(false));

	const totalFilterCount = useMemo(
		() => filters.reduce((acc, filter) => acc + (filter.count || 0), 0),
		[filters],
	);

	const accordionOpened = useMemo(() => {
		return (
			filterOpened &&
			(source === "saved_people" ||
				(source === "results" && activeContent === "search_results"))
		);
	}, [activeContent, filterOpened, source]);

	const handleResetClick = useCallback(() => {
		dispatch(clearAPFilters());
	}, [dispatch]);

	return (
		<div
			ref={ref}
			style={{
				position: "relative",
				pointerEvents: disabled ? "none" : "auto",
				cursor: disabled ? "not-allowed" : "pointer",
				opacity: disabled ? "0.6" : 1,
			}}
		>
			<div
				className={classNames(
					styles.filterBlockButtonWrapper,
					source === "results" && activeContent === "profile_request" && activeClass,
					{
						[styles.opened]: filterOpened,
					},
					className,
				)}
				onClick={handleAPFilterClick}
				style={{cursor: canUserClick ? "pointer" : "auto"}}
			>
				<span
					className={classNames(styles.title, titleClass && titleClass, {
						[styles.disabled]: disabled,
					})}
					role="filter-button"
				>
					<span className={classNames(styles.icon, {[styles.disabled]: disabled})}>
						<FilterIcon
							width={source === "saved_people" ? 20 : 28}
							height={source === "saved_people" ? 20 : 28}
						/>
					</span>
					Filter
					{disabled ? (
						<LockIcon className={styles.filterExpandIcon} />
					) : (
						<ChevronIcon
							className={classNames(styles.filterExpandIcon, {
								[styles.rotated]: !filterOpened,
							})}
							width={24}
							height={24}
						/>
					)}
				</span>

				{totalFilterCount > 0 && <span className={styles.count}>{totalFilterCount}</span>}
			</div>
			<div
				className={classNames(styles.filters, {
					[styles.filterDisplayed]: filterOpened,
				})}
				style={{top: source === "saved_people" ? "36px" : "57px"}}
			>
				{accordionOpened &&
					filters.map((item) => {
						return (
							<Accordion
								key={`${item.type}-${item.name}-${item.key}`}
								type={`${item.type}`}
								attribute={item.key}
								title={item.name}
								subtitle={item?.count || 0}
								contentCustomStyle={{
									margin: "auto",
								}}
								accordionCss={classNames(
									styles.accordion,
									styles.resultFilterAccordion,
								)}
								disabled={item?.disabled}
								displayLock={item?.displayLock}
							>
								{item.filter}
							</Accordion>
						);
					})}

				{shouldDisplayResetFilter && accordionOpened && (
					<div className={classNames(styles.accordion, styles.resetFilterAccordion)}>
						<ResetFilters
							parentStyle={{color: "#868D8D", marginRight: 19}}
							onClick={handleResetClick}
							displayIcon={true}
						>
							Reset Filters
						</ResetFilters>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProjectCandidatesFilter;
